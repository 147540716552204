.feature-connect {
    position: relative; 
}
.feature-track {
    position: relative; 
}
.feature-analyze {
    position: relative; 
}

.feature-connect::before {    
      content: "";
      background-size: cover;
      background-image: url("https://images.unsplash.com/photo-1598516802414-50a01bee818d?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=934&q=80");
      position: absolute;
      top: 0px;
      right: 0px;
      transition: "opacity .25s ease-in-out;";
      opacity: 0.5;
      bottom: 0px;
      left: 0px;
}
.feature-track::before {    
      content: "";
      background-size: cover;
      background-image: url("https://images.unsplash.com/photo-1538805060514-97d9cc17730c?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=934&q=80");
      position: absolute;
      top: 0px;
      opacity: 0.5;
      right: 0px;
      bottom: 0px;
      left: 0px;
}
.feature-analyze::before {    
      content: "";
      background-size: cover;
      background-image: url("https://images.unsplash.com/photo-1560221328-12fe60f83ab8?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=1653&q=80");
      position: absolute;
      opacity: 0.5;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
}
