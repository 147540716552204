.auth {
    position: relative; 
}

.auth::before {    
      content: "";
      background-size: cover;
      background-image: url("https://images.unsplash.com/photo-1550345332-09e3ac987658?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=934&q=80");
      position: absolute;
      top: 0px;
      right: 0px;
      opacity: 0.30;
      filter: grayscale();
      bottom: 0px;
      left: 0px;
}
