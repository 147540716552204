.hero {
    position: relative; 
    height: 70vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.hero::before {    
      content: "";
      background-image: url('https://images.unsplash.com/photo-1519505907962-0a6cb0167c73?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80');
      background-size: cover;
      position: absolute;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
      opacity: 0.25;
}
