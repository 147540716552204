.mobile {
    position: relative; 
}

.mobile::before {    
      content: "";
      background-size: cover;
      background-image: url("https://images.unsplash.com/photo-1574680178050-55c6a6a96e0a?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1650&q=80");
      position: absolute;
      top: 0px;
      right: 0px;
      opacity: 0.10;
      filter: grayscale();
      bottom: 0px;
      left: 0px;
}
